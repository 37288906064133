$(function () {
    $("#banner-owl-demo").owlCarousel({
        autoPlay: true,
        navigation: false, 
        pagination: false,
        slideSpeed: 300,
        singleItem: true
    });

});

















